import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { authService } from './authService/authService';
import Login from './components/Login/Login';
import ControlPanel from './components/ControlPanel/ControlPanel';
import './App.css';


function App() {
  // Suivre si l'utilisateur est connecté ou non grace au BehaviorSubject du service authService
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const subscription = authService.subscribe((isAuthenticated) => {
      setIsLoggedIn(isAuthenticated);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // approche intéressante pour protéger une route grâce à un Observable
      return (
        <div className="App">
          <Router>
            <Routes>

              <Route path="/" element={<Login />} />
              <Route path="/cpanel" element={isLoggedIn ? <ControlPanel /> : <Navigate to="/" />} />
              <Route path="*" element={<Navigate to="/" />} />

{/* <Route path="/" element={<ControlPanel />} /> */}
            </Routes>
          </Router>
        </div>
      );
    };


export default App;



