import React, { useState } from 'react';
import placeholder from '../../images/placeholder.png';
import axios from 'axios';

const isImageURL = (url) => {
  const imageExtensions = ['.jpeg', '.jpg', '.gif', '.png'];
  // Vérifier si l'URL contient une extension d'image
  return imageExtensions.some(ext => url.includes(ext));
};

const NouvellePublication = ({ onClose, onSave }) => {
  const [nouvelle, setNouvelle] = useState({
    titre: '',
    date: '',
    lienImage: '',
    lienDA: '',
    extrait: '',
    texte: ''
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNouvelle({ ...nouvelle, [name]: value });
  };

  const handleSave = () => {
    onSave(nouvelle); // Appeler la fonction handleCreateNouvelle définie dans le composant ControlPanel
  };

  return (
    <div className="modal">
      <div className="modal-content">
        {/* Aperçu de la nouvelle publication */}
        <div className="partie_apercu">
          <h2>Aperçu</h2>

          <div className='alignement-box'>
            <div className='alignement-b'>
              <h3>{nouvelle.titre}</h3>
              <p>{nouvelle.date}</p>
            </div>
            <div className='alignement-a'>
              {isImageURL(nouvelle.lienImage) ? (
                <img src={nouvelle.lienImage} className='miniature_image' alt="Image de la nouvelle"/>
              ) : (
                <img src={placeholder} className='miniature_image' alt="Image de la nouvelle"/>
              )}
            </div>
          </div>
          <div className='linkimg'>
            <span>Lien Image</span>
            <div className='containerlienimg'>
              <p>{nouvelle.lienImage}</p>
            </div>
          </div>
          <div className='attrib'>
            <span>Lien attribution d'image</span>
            <p>{nouvelle.lienDA}</p>
          </div>
          <div className='extr'>
            <span>Extrait choisi</span>
            <p>{nouvelle.extrait}</p>
          </div>
          <div className='txt_app cute_scroll'>
            <span>Texte</span>
            <div className='textareaappercutexte cute_scroll'>{nouvelle.texte}</div>
          </div>
        </div>

        {/* Partie de modification */}
        <div className="partie_modification">

          <div className='box-modification-btn'>
            <div className='box-m-1'>
                <h2>Nouvelle publication</h2>
            </div>
            <div className='box-m-3 btnmodification'>
              <button onClick={onClose}>Annuler</button>
            </div>
            <div className='box-m-2 btnmodification'>
              <button onClick={handleSave}>Enregistrer</button>
            </div>
          </div>


          <div className='mod_titre'>
            <input type="text" name="titre" value={nouvelle.titre} onChange={handleInputChange} placeholder="Titre" />
            <input type="text" name="date" value={nouvelle.date} onChange={handleInputChange} placeholder="Date" />
          </div>
          <div className='mod_img'>
            <span>Lien Image:</span>
            <input type="text" name="lienImage" value={nouvelle.lienImage} onChange={handleInputChange} placeholder="Lien de l'image" />
          </div>

          <div className='mod_imgda'>
            <span>Lien DA:</span>
            <input type="text" name="lienDA" value={nouvelle.lienDA} onChange={handleInputChange} placeholder="Lien de l'attribution d'image" />
          </div>

          <div className='mod_extr'>
            <span>Extrait choisi:</span>
            <textarea rows={4} maxLength={230} type="text" name="extrait" value={nouvelle.extrait} onChange={handleInputChange} placeholder="Extrait choisi" />
          </div>

          <div className='mod_txt'>
            <span>Texte:</span>
            <textarea 
            className="cute_scroll" name="texte" value={nouvelle.texte} onChange={handleInputChange} placeholder="Texte" />
          </div>

        </div>
      </div>
    </div>
  );
};

export default NouvellePublication;
