import React, { useEffect, useState } from 'react';
import axios from 'axios';
import NouvelleModal from '../NouvelleModal/NouvelleModal';
import NouvellePublication from '../NouvellePublication/NouvellePublication';
import { Link } from 'react-router-dom';
import { updateAuthState } from '../../authService/authService';

const ControlPanel = () => {
  const [nouvelles, setNouvelles] = useState([]);
  const [selectedNouvelle, setSelectedNouvelle] = useState(null);
  const [isModifying, setIsModifying] = useState(false);
  const [isNewNovel, setIsNewNovel] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const fetchNouvelles = async () => {
    try {
      const response = await axios.get('https://luckycheetah.fun/apibiographe/fetchnouvelles');
      setNouvelles(response.data.documents || []);
    } catch (error) {
      console.error('Erreur lors de la récupération des nouvelles:', error);
    }
  };

  useEffect(() => {
    fetchNouvelles();
  }, []);

  const handleDetailsClick = (nouvelle) => {
    setSelectedNouvelle(nouvelle);
  };

  const handleDisconnect = () => {
    updateAuthState(false);
  };

  const handleNewNovelClick = () => {
    setIsNewNovel(true); // Définir isNewNovel sur true lorsque le bouton "Nouvelle publication" est cliqué
  };

  const handleModifierClick = (nouvelle) => {
    setSelectedNouvelle(nouvelle);
    setIsModifying(true); // Afficher la fenêtre de modification
  };

  const handleSaveNouvelle = async (editedNouvelle) => {
    try {
      const response = await axios.put(`https://luckycheetah.fun/apibiographe/modification/${editedNouvelle._id}`, editedNouvelle);
      setNouvelles(nouvelles.map(n => n._id === editedNouvelle._id ? editedNouvelle : n));
      setSuccessMessage('Modifications enregistrées avec succès !');
      setTimeout(() => {
        setSuccessMessage('');
        setIsModifying(false); // Fermer la fenêtre de modification et retourner à la liste après 3 secondes
      }, 3000);
    } catch (error) {
      console.error('Erreur lors de la sauvegarde de la nouvelle modifiée:', error);
      setErrorMessage('Erreur lors de l\'enregistrement des modifications.');
      setTimeout(() => setErrorMessage(''), 3000); // Disparaître après 3 secondes
    }
  };

  const handleCreateNouvelle = async (newNouvelle) => {
    try {
      const response = await axios.post('https://luckycheetah.fun/apibiographe/creer', newNouvelle);
      setNouvelles([...nouvelles, response.data]); // Ajouter la nouvelle publication à la liste existante
      setSuccessMessage('Nouvelle publication créée avec succès !');
      setTimeout(() => {
        setSuccessMessage('');
        setIsNewNovel(false); // Fermer le formulaire de création et retourner à la liste après 3 secondes
      }, 3000);
      fetchNouvelles(); // Récupérer à nouveau la liste des nouvelles après la création
    } catch (error) {
      console.error('Erreur lors de la création de la nouvelle publication:', error);
      setErrorMessage('Erreur lors de la création de la nouvelle publication.');
      setTimeout(() => setErrorMessage(''), 3000); // Disparaître après 3 secondes
    }
  };

  const handleCloseModal = () => {
    setSelectedNouvelle(null);
    setIsModifying(false); // Cacher la fenêtre de modification si elle est ouverte
  };

  const handleSupprimerClick = (nouvelle) => {
    deleteNouvelle(nouvelle);
  };

  const deleteNouvelle = async (nouvelle) => {
    try {
      const response = await axios.delete(`https://luckycheetah.fun/apibiographe/suppression/${nouvelle._id}`, {
        data: { _rev: nouvelle._rev }
      });
      
      setNouvelles(nouvelles.filter(item => item._id !== nouvelle._id));
      setSuccessMessage('Nouvelle supprimée avec succès !');
      setTimeout(() => setSuccessMessage(''), 3000); // Disparaître après 3 secondes
    } catch (error) {
      console.error('Erreur lors de la suppression de la nouvelle :', error);
      setErrorMessage('Erreur lors de la suppression de la nouvelle.');
      setTimeout(() => setErrorMessage(''), 3000);
    }
  };

  const nouvellesTrie = nouvelles.slice().sort((a, b) => {
    const dateA = a.date ? new Date(a.date.split('/').reverse().join('/')) : new Date();
    const dateB = b.date ? new Date(b.date.split('/').reverse().join('/')) : new Date();
    return dateB - dateA;
  });

  return (
    <div className="controlPanel">
      <div className='titleContainer'>
        <div className='ff1'>
          <h1>Control Panel</h1>
          <div className='ff2'><Link to="/"><button onClick={() => handleDisconnect()} className='btndisconnect'>Deconnexion</button></Link></div>
        </div>

        <p>{successMessage || errorMessage ? null : 'Interface de gestion de publication des nouvelles de Patrick Collet'}</p>
        {successMessage && <div className="success-message">{successMessage}</div>}
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>
      {!isModifying && !isNewNovel && ( // Afficher la table si la modification n'est pas en cours
        <table className='cute_scroll'>
          <thead>
            <tr>
              <th>Date</th>
              <th>Titre</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {nouvellesTrie.map((nouvelle) => (
              <tr key={nouvelle._id}>
                <td>{nouvelle.date}</td>
                <td onClick={() => handleDetailsClick(nouvelle)}>{nouvelle.titre}</td>
                <td>
                  <button className='btn_modifier' onClick={() => handleModifierClick(nouvelle)}>Modifier</button>
                  <button className='btn_supprimer' onClick={() => handleSupprimerClick(nouvelle)}>Supprimer</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {isModifying && selectedNouvelle && ( // Afficher la fenêtre de modification si elle est ouverte
        <NouvelleModal
          nouvelle={selectedNouvelle}
          onSave={handleSaveNouvelle}
          onClose={handleCloseModal}
        />
      )}

      {!isModifying && !isNewNovel && (
        <button className='btn_new_novel' onClick={handleNewNovelClick}>
          Nouvelle publication
        </button>
      )}

      {isNewNovel && (
        <NouvellePublication onClose={() => setIsNewNovel(false)} onSave={handleCreateNouvelle} />
      )}
    </div>
  );
};

export default ControlPanel;
