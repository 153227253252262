import { BehaviorSubject } from 'rxjs';

export const authService = new BehaviorSubject(false);

export const updateAuthState = (isLoggedIn) => {
  authService.next(isLoggedIn);
};



/* 
Le BehaviorSubject est un type d'observable fourni par RxJS. 
Il maintient une valeur courante et émet cette valeur à tout nouvel abonné.
la valeur initiale est false, ce qui signifie que l'utilisateur n'est pas authentifié au départ.

Cette fonction updateAuthState prend un paramètre isLoggedIn, qui est un booléen indiquant si l'utilisateur est 
authentifié ou non. Lorsque cette fonction est appelée, elle utilise la méthode next() de l'objet BehaviorSubject 
authService pour mettre à jour la valeur courante de l'observable. 

Ainsi, en appelant updateAuthState(true), nous mettons à jour l'état d'authentification pour indiquer que 
l'utilisateur est connecté, et en appelant updateAuthState(false), nous mettons à jour l'état d'authentification 
pour indiquer que l'utilisateur n'est pas connecté.

Cela fournit un moyen pratique de gérer l'état d'authentification dans l'ensemble de l'application en utilisant 
des observables RxJS.
*/