import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Importez useNavigate
import placeholder from '../../images/placeholder.png';

const isImageURL = (url) => {
  const imageExtensions = ['.jpeg', '.jpg', '.gif', '.png'];
  
  return imageExtensions.some(ext => url.includes(ext));
};

const NouvelleModal = ({ nouvelle, onClose, onSave }) => {
  const [editedNouvelle, setEditedNouvelle] = useState(nouvelle);
  const navigate = useNavigate(); // Utilisation de useNavigate pour la redirection

  useEffect(() => {
    setEditedNouvelle(nouvelle);
  }, [nouvelle]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedNouvelle({ ...editedNouvelle, [name]: value });
  };

  const handleSave = () => {
    onSave(editedNouvelle);
    // Après la sauvegarde réussie, rediriger après 3 secondes

  };

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="partie_apercu">
          <h2>Aperçu</h2>

          <div className='alignement-box'>
            <div className='alignement-b'>
              <h3>{editedNouvelle.titre}</h3>
              <p>{editedNouvelle.date}</p>
            </div>
            <div className='alignement-a'>
              {isImageURL(editedNouvelle.lienImage) ? (
                <img src={editedNouvelle.lienImage} className='miniature_image' alt="Image de la nouvelle"/>
              ) : (
                <img src={placeholder} className='miniature_image' alt="Image de la nouvelle"/>
              )}
            </div>
          </div>

          <div className='linkimg'>
            <span>Lien Image</span>
            <div className='containerlienimg'>
              <p>{editedNouvelle.lienImage}</p>
            </div>
          </div>

          <div className='attrib'>
            <span>Lien attribution d'image</span>
            <p>{editedNouvelle.lienDA}</p>
          </div>

          <div className='extr'>
            <span>Extrait choisi</span>
            <p>{editedNouvelle.extrait}</p>
          </div>

          <div className='txt_app cute_scroll'>
            <span>Texte</span>
            <div className='textareaappercutexte cute_scroll'>{editedNouvelle.texte}</div>
          </div>
        </div>

        <div className="partie_modification">
          <div className='box-modification-btn'>
            <div className='box-m-1'>
              <h2>Modification</h2>
            </div>
            <div className='box-m-3 btnmodification'>
              <button onClick={onClose}>Annuler</button>
            </div>
            <div className='box-m-2 btnmodification'>
              <button onClick={handleSave}>Enregistrer</button>
            </div>
          </div>

          <div className='mod_titre'>
            <input type="text" name="titre" value={editedNouvelle.titre} onChange={handleInputChange}/>
            <input type="text" name="date" value={editedNouvelle.date} onChange={handleInputChange} />
          </div>

          <div className='mod_img'>
            <span>Lien Image:</span>
            <input type="text" name="lienImage" value={editedNouvelle.lienImage} onChange={handleInputChange}/>
          </div>

          <div className='mod_imgda'>
            <span>Lien DA:</span>
            <input type="text" name="lienDA" value={editedNouvelle.lienDA} onChange={handleInputChange} />
          </div>

          <div className='mod_extr'>
            <span>Extrait choisi:</span>
            <textarea rows={4} maxLength={230} type="text" name="extrait" value={editedNouvelle.extrait} onChange={handleInputChange} />
          </div>

          <div className='mod_txt'>
            <span>Texte:</span>
            <textarea 
              className="cute_scroll" name="texte" value={editedNouvelle.texte} onChange={handleInputChange} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NouvelleModal;
