import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateAuthState } from '../../authService/authService';
import imageNice from '../../images/Nice.jpg';
import axios from 'axios';

const sanitize = (str) => {
  return str
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#x27;")
    .replace(/\//g, "&#x2F;");
};

const Login = () => {
  const [identifiant, setidentifiant] = useState('');
  const [motDePasse, setmotDePasse] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonSuccess, setButtonSuccess] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    
    try {
      setButtonLoading(true);
    
      const response = await axios.post('https://luckycheetah.fun/apibiographe/connexion', {
        identifiant,
        motDePasse,
      });
    
      if (response.data.success) {
        setButtonSuccess(true);
        setMessage('Connection success !');
        updateAuthState(true); 
        navigate('/cpanel');
      } else {
        setError('Identifiant ou mot de passe incorrect');
      }
    } catch (error) {
      setError('Une erreur est survenue, veuillez réessayer plus tard');
    } finally {
      setTimeout(() => {
        setButtonLoading(false);
        setButtonSuccess(false);
      }, 2000);
    }
  };

  return (
    <section className='landingContainer'>
      <div className='containerSides'>
        <div className='side_form'>
          <form onSubmit={handleLogin}>
            <div className="titleContainer">
              <h1>Bienvenu dans le</h1>
              <h2>Control panel de pcolletbiographe.fr</h2>
            </div>
            <div>
              <input 
                className='inputForm' 
                name="login-username" 
                type="password" 
                value={identifiant} 
                onChange={(e) => { 
                  setidentifiant(e.target.value);
                  if (e.target.value.trim() !== "") {
                    e.target.classList.add("has-text");
                  } else {
                    e.target.classList.remove("has-text");
                  }
                }}
              />       
              <label htmlFor="login-username">Identifiant:</label>
            </div>
            <div>
              <input 
                className='inputForm' 
                name="login-pass" 
                type="password" 
                value={motDePasse} 
                onChange={(e) => { 
                  setmotDePasse(e.target.value);
                  if (e.target.value.trim() !== "") {
                    e.target.classList.add("has-text");
                  } else {
                    e.target.classList.remove("has-text");
                  }
                }}
              />
              <label htmlFor="login-pass">Mot de passe:</label>
            </div>
            <button className={`button ${buttonLoading ? 'loading' : ''} ${buttonSuccess ? 'success' : ''}`} type="submit" id="btn-submit">
              <span className="button-text">
                {buttonLoading ? 'Chargement...' : buttonSuccess ? 'Login Successful' : 'Connexion'}
              </span>
              <div className="button-loader">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </button>

            {message && <div>{sanitize(message)}</div>}
            {error && <div>{sanitize(error)}</div>}

          </form>
        </div>
        <div className='side_image'>
          <img src={imageNice} alt="schéma d'un panneau de configuration" />
        </div>
      </div>
    </section>
  );
};

export default Login;
